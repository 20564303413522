<template>
  <div
    class="row csn-self-exclusion csn-user-account-section"
    id="user-account"
  >
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>{{ t('title') }}</h1>
          <Message
            :type="messageTypeEnum.NOTICE"
            :message="noticeMessage"
            :hasBottom="true"
            @close="closeNoticeMessage"
          />
          <Message
            :type="messageTypeEnum.ERROR"
            :message="reasonMessage"
            :hasBottom="true"
            @close="closeReasonMessage"
          />
          <h2>{{ t('subtitle') }}</h2>
          <p>{{ t('description') }}</p>
        </section>
        <section>
          <h2>{{ t('form_title') }}</h2>
          <Form class="csn-self-exclusion-form" :ref="formRef">
            <Message :type="messageType" :message="message" />
            <div class="row-fluid">
              <Dropdown
                :dataSource="periodList"
                :label="t('form_period_label')"
                :name="name.PERIOD_TYPE"
                v-model="formData[name.PERIOD_TYPE]"
                :validators="[validator.required]"
                :serverError="serverError[name.PERIOD_TYPE]"
                containerClass="form-group"
                inputClass="form-control"
              />
            </div>
            <div class="row-fluid">
              <Dropdown
                :dataSource="reasonList"
                :label="t('form_reason_for_deactivation')"
                :name="name.DEACTIVATION_REASON"
                v-model="formData[name.DEACTIVATION_REASON]"
                :serverError="serverError[name.DEACTIVATION_REASON]"
                containerClass="form-group"
                inputClass="form-control"
              />
            </div>
            <div class="form-group csn-self-exclusion-btn-container">
              <button
                type="submit"
                class="btn casino-btn csn-self-exclusion-btn casino-btn-theme-based"
                @click="submitForm"
                :disabled="isSubmitting"
              >
                <ButtonLoader v-if="isSubmitting" />
                <span v-else>{{ t('submit_bttn_label') }}</span>
              </button>
            </div>
          </Form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { formMixin } from '@/mixins'
import { USER_SELF_EXCLUSION, EMPTY_STRING, Digit } from '@/constants'
import { AuthApi } from '@/api'

const name = {
  PERIOD_TYPE: 'selfExclusion_periodType',
  DEACTIVATION_REASON: 'selfExclusion_deactivationReason',
}

const formData = {
  [name.PERIOD_TYPE]: EMPTY_STRING,
  [name.DEACTIVATION_REASON]: EMPTY_STRING,
}

export default {
  name: USER_SELF_EXCLUSION,
  mixins: [formMixin],
  components: {
    Form: () => import('@/components/FormData'),
    Dropdown: () => import('@/components/Dropdown'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
  },
  data: () => ({
    formData: { ...formData },
    rendersNoticeMessage: true,
    reasonList: [],
    reasonMessage: null,
  }),
  computed: {
    t() {
      return this.$createComponentTranslator(USER_SELF_EXCLUSION)
    },
    name: () => name,
    noticeMessage() {
      return this.rendersNoticeMessage ? this.t('notice') : EMPTY_STRING
    },
    periodList() {
      return [
        { name: this.t('day'), value: Digit.ONE },
        { name: this.t('three_days'), value: Digit.TWO },
        { name: this.t('week'), value: Digit.THREE },
        { name: this.t('month'), value: Digit.FOUR },
        { name: this.t('three_months'), value: Digit.FIVE },
        { name: this.t('six_months'), value: Digit.SIX },
        { name: this.t('year'), value: Digit.SEVEN },
      ]
    },
  },
  methods: {
    closeNoticeMessage() {
      this.rendersNoticeMessage = false
    },
    closeReasonMessage() {
      this.reasonMessage = false
    },
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }

      const requestData = {
        user_self_exclusion: {
          selfExclusionPeriod: this.formData[name.PERIOD_TYPE],
          selfExclusionReason: this.formData[name.DEACTIVATION_REASON],
        },
      }

      try {
        const response = await AuthApi.postSelfExclusion(requestData)

        if (!this.validateResponse(response)) {
          return
        }

        const { state, msg } = response

        if (state === this.responseState.ERROR) {
          this.messageType = this.messageTypeEnum.FAILURE
          this.message = msg
          return
        }

        this.messageType = this.messageTypeEnum.SUCCESS
        this.message = msg
      } catch (e) {
        console.log(e)
      }
    },
    async getReasons() {
      try {
        const { data, msg, state } = await AuthApi.getSelfExclusionReasons()

        if (state === this.responseState.ERROR) {
          this.reasonMessage = msg
          return
        }

        const list = []

        for (const [key, value] of Object.entries(data?.items || {})) {
          list.push({ name: value, value: Number(key) })
        }

        this.reasonList = list
      } catch (e) {
        console.log(e)
      }
    },
  },
  created() {
    this.getReasons()
  },
}
</script>
